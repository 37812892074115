.file-input {
  display: none;
}
.drop-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 100%;
  height: 200px;
  border: 4px dashed #4aa1f3;
}